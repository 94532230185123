.App {
  text-align: center;
  overflow-x: hidden; 
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
}
.App-link {
  color: #61dafb;
}
.icon-fixed {
  position: fixed;
  bottom: 10px; 
  z-index: 1000; 
}
.watsapp {
  left: 30px; 
  bottom: 30px;
}
.phone{
  right: 30px; 
  bottom: 30px;
  color: green;
}
.faWhatsapp,
.faPhone {
  color: inherit; 
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 768px) {
  .icon-fixed.watsapp .fa-whatsapp {
    font-size: 2em; 
  }
  
  .icon-fixed.phone .fa-phone {
    font-size: 1.5em; 
  }
}
